<template>
  <div class="issues-defects">
    <el-form
      class="analysis-page__form-box"
      ref="form"
      :model="formData"
      inline
      label-position="left"
    >
      <el-form-item label="Location" prop="towerLocation">
        <el-select
          v-model="formData.towerLocation"
          placeholder="All Locations"
          @change="handleSearch"
          clearable
        >
          <el-option
            v-for="item in locationOptions"
            :key="item.dictValue"
            :label="item.dictLabel"
            :value="item.dictValue"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Time and Date" prop="time">
        <SelectDate
          type="datetime"
          placeholder="All Date and Time"
          v-model="formData.beginTime"
          @change="handleSearch"
          :disabledDate="disabledDate"
        ></SelectDate>
      </el-form-item>
    </el-form>

    <div class="issues-defects__content _flex">
      <div class="content-left">
        <div class="content-left__date _flex _flex-justify__between">
          <div>
            <span class="_font-weight__600">Location: </span
            >{{ resolution(formData, 'towerLocation') || common.EMPTY_TEXT }}
          </div>
          <div><span class="_font-weight__600">Date: </span>{{ renderTime() }}</div>
        </div>

        <div class="content-left__overview">
          <div class="content-left__overview-title _font-weight__600">Tower Overview</div>
          <el-image :src="Bitmap" fit="cover"></el-image>
          <div
            :style="{
              opacity: circleHeight ? 1 : 0,
              bottom: pxConvertRem(circleHeight * 43)
            }"
            class="circle-red"
          ></div>
        </div>
      </div>
      <div class="content-right">
        <el-form
          class="analysis-page__form-box"
          ref="listQueryForm"
          :model="formData"
          inline
          label-position="left"
        >
          <el-form-item
            v-for="item in listFormView"
            :key="item.prop"
            :label="item.label"
            :prop="item.prop"
          >
            <el-input
              v-model="formData[item.prop]"
              placeholder="e.g, DF12345"
              class="placeholder-gray"
              @blur="handleSearch"
              v-if="item.type === 'input'"
            >
            </el-input>
            <el-select
              v-model="formData[item.prop]"
              placeholder="All"
              clearable
              @change="handleSearch(item.prop)"
              v-else-if="item.type === 'select'"
            >
              <el-option
                v-for="each in item.options"
                :key="each.dictValue"
                :label="each.dictLabel"
                :value="each.dictValue"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <Transition name="fade-opacity">
          <KeepAlive>
            <Component
              ref="table"
              :is="componentName === 'defects' ? 'defects-table' : 'issues-table'"
              :formData="{ ...formData, towerCode: code }"
              @view-detail="(row) => $emit('view-detail', row)"
              @current="handleCurrent"
            ></Component>
          </KeepAlive>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import Bitmap from '@/assets/Bitmap.png'
import common from '@/common'
import SelectDate from '@/components/select-datetime/select-date'
import { parseTime, optionsFun } from '@/utils'
import { alertTowerLocation } from '@/api/home'
import { listFormView } from '../index-data'
import IssuesTable from './issues-table'
import DefectsTable from './defects-table'

export default {
  components: {
    IssuesTable,
    DefectsTable,
    SelectDate
  },
  props: {
    componentName: String,
    code: {
      type: String,
      default: common.code
    }
  },
  data() {
    return {
      formData: {
        defectId: '',
        severity: '',
        towerLocation: '',
        type: '',
        beginTime: ''
      },
      common,
      circleHeight: 0,
      locationOptions: [],
      listFormView: listFormView.filter((item) => item.prop !== 'type'),
      Bitmap
    }
  },
  watch: {
    '$route.query': {
      handler(val) {
        if (val.time) {
          this.formData.beginTime = `${val.time} 00:00:00`
        } else this.formData.beginTime = ''
      },
      immediate: true
    },
    componentName: {
      handler(val, oldValue) {
        // 动态塔清除数据
        if (this.code === common.code) {
          this.formData.towerLocation = ''
        }
        if (val === 'defects' && oldValue !== val) {
          this.listFormView.push(listFormView.find((item) => item.prop === 'type'))
        } else if (this.listFormView.length === 3 && val !== 'defects') {
          this.listFormView.splice(2, 1)
        }
      },
      immediate: true
    }
  },
  mounted() {
    alertTowerLocation().then((res) => {
      this.locationOptions = optionsFun(res.result)
    })
  },
  methods: {
    disabledDate(time) {
      return this.$store.state.user.isOn
        ? !['2023-11-07', '2023-11-08'].includes(parseTime(time, '{y}-{m}-{d}'))
        : false
    },
    handleCurrent(e) {
      if (e.location) {
        this.circleHeight = Number(e.location.replace('m', ''))
      } else this.circleHeight = 0
    },
    renderTime() {
      return `
      ${this.formData.beginTime ? parseTime(this.formData.beginTime, 'reg') : common.EMPTY_TEXT} 
      ${
        this.formData.beginTime ? parseTime(this.formData.beginTime, '{h}:{i}') : common.EMPTY_TEXT
      } 
      `
    },
    handleSearch(type) {
      switch (type) {
        case 'type':
          break
        default:
          setTimeout(() => {
            if (this.componentName === 'defects') {
              this.$refs.table.lists.forEach((item) => {
                this.$refs.table.getData(item)
              })
            } else {
              this.$refs.table.getData()
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('../../../styles/cover.scss');
.placeholder-gray {
  & ::v-deep input::placeholder {
    color: #979797 !important;
  }
}
.issues-defects {
  ::v-deep .el-table {
    &__body tr {
      transition: all 0.3s;
      &:hover .cell {
        background: #fdeaeb;
      }

      .cell {
        height: 55px;
        line-height: 35px;
        padding: 10px 20px;
      }
    }
    .current-row {
      color: var(--color-primary);
      background: #fdeaeb;
    }

    .operate-group {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__content {
    .content-left {
      width: 490px;
      font-size: 16px;
      background: var(--color-white);

      &__date {
        padding: 15px 20px;
        border-bottom: 1px solid var(--color-info-light-5);
      }

      &__overview {
        padding: 15px 20px;
        height: 776px;
        position: relative;

        &-title {
          margin-bottom: 20px;
        }

        .el-image {
          width: 100%;
          height: calc(100% - 40px);
        }

        @keyframes circleAnimation {
          0% {
            opacity: 0.15;
            transform: scale(1);
          }
          80% {
            transform: scale(2.5);
            opacity: 0.15;
          }
          100% {
            transform: scale(3);
            opacity: 0;
          }
        }

        .circle-red {
          width: 10px;
          height: 10px;
          transition: all 0.4s;
          background: var(--color-primary);
          position: absolute;
          left: 50%;
          border-radius: 50%;
          transform: translateX(-50%);

          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: var(--color-primary);
            animation: circleAnimation 1.6s infinite;
            -webkit-animation: circleAnimation 1.6s infinite; /* Safari 与 Chrome */
          }
        }
      }
    }

    .content-right {
      width: calc(100% - 490px - 50px);
      margin-left: 50px;

      ::v-deep &__lists {
        .list-item {
          padding: 20px 20px 0 20px;
          background: var(--color-white);

          &__label {
            margin-bottom: 25px;
          }
        }

        .list-item + .list-item {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
